var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.disabled
        ? _c(
            "a-select",
            {
              style: _vm.styles ? _vm.styles : "min-width: 200px;",
              attrs: {
                mode: "multiple",
                placeholder: _vm.placeholder,
                value: _vm.selectedItems,
                defaultValue: _vm.defaultValue,
                disabled: _vm.disabled,
                "filter-option": _vm.filterOption
              },
              on: { change: _vm.handleChange }
            },
            _vm._l(_vm.filteredOptions, function(item) {
              return _c(
                "a-select-option",
                { key: item.id, attrs: { value: item.id } },
                [_vm._v("\n      " + _vm._s(item.name) + "\n    ")]
              )
            }),
            1
          )
        : _vm._e(),
      _vm.disabled ? _c("a-tag", [_vm._v(_vm._s(_vm.selectName))]) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }