<template>
  <div>
    <div class="mb-3">
      <h1 :class="$style.pageTitle" style="display:inline-block;">統計情報</h1>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header" style="padding-left: 10px !important;">
            <div :class="$style.filterHeader">フィルター</div>
            <a-form layout="inline">
              <a-form-item label="社名">
                <form-multiple-select
                  placeholder="社名"
                  :candidates="companyData"
                  style="min-width: 200px"
                  @changeSelectedItems="changeSelectedCompanyName"
                />
              </a-form-item>
              <a-form-item label="ID">
                <form-multiple-select
                  placeholder="ID"
                  :candidates="companyDataIds"
                  style="min-width: 200px"
                  @changeSelectedItems="changeSelectedCompanyId"
                />
              </a-form-item>
            </a-form>
            <hr style="margin: 20px 0 0 0">
          </div>
          <div class="card-body">
            <div v-if="loading" class="text-center" >
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!loading">
              <a-table
                :columns="columns"
                :data-source="filteredCompanyData"
                :pagination="{ pageSize: 20 }"
                rowKey="id"
                :rowClassName="(r, i) => i % 2 === 0 ? $style.stripe0 : $style.stripe1"
              >
                <span slot="name" slot-scope="name, record">{{ record.id }}: {{ name }}</span>
                <span slot="ratio_active" slot-scope="id, record">{{ record.statistics_active_num / record.statistics_account_num | formatRatio }}</span>
                <span slot="statistics_date_time" slot-scope="statistics_date_time">{{ statistics_date_time | formatDateTime }}</span>
                <span slot="belong" slot-scope="id, record">
                  <a-button type="primary" shape="round" @click="showModalBelong(record.id)">所属</a-button>
                </span>
                <span slot="update" slot-scope="id, record">
                  <router-link :to="{ name: 'companyUpdate', params: { id: record.id, company: record }}">
                    <a-button style="padding: 0 10px; margin-right: 4px;">編集</a-button>
                  </router-link>
                </span>
              </a-table>
              <a-modal
                :title="title"
                :visible="visibleBelong"
                :confirm-loading="confirmLoading"
                okText='所属する'
                okType='primary'
                cancelText='キャンセル'
                @ok="handleOkBelong"
                @cancel="handleCancelBelong"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
</style>
<script>
import Vue from 'vue'
import moment from 'moment'
import FormMultipleSelect from '../../../components/FormComponents/MultipleSelect'

export default {
  components: {
    FormMultipleSelect,
  },
  data() {
    return {
      loading: true,
      companyData: [],
      title: '',
      visibleBelong: false,
      confirmLoading: false,
      dataBelong: {
        company_id: null,
      },
      belongTargetCompany: Object,

      selectedCompanies: [],
      selectedCompanieIds: [],

      columns: [
        {
          title: '名前',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          sorter: (a, b) => {
            return (a.name || '').localeCompare(b.name)
          },
        },
        {
          title: 'アカウント数',
          dataIndex: 'statistics_account_num',
          scopedSlots: { customRender: 'statistics_account_num' },
          sorter: (a, b) => {
            return a.statistics_account_num - b.statistics_account_num
          },
          width: 120,
        },
        {
          title: 'アクティブ数',
          dataIndex: 'statistics_active_num',
          scopedSlots: { customRender: 'statistics_active_num' },
          sorter: (a, b) => {
            return new Date(a.statistics_active_num) - new Date(b.statistics_active_num)
          },
          defaultSortOrder: 'descend',
          width: 120,
          align: 'center',
        },
        {
          title: 'アクティブ率',
          scopedSlots: { customRender: 'ratio_active' },
          sorter: (a, b) => {
            const aR = a.statistics_account_num ? (a.statistics_active_num / a.statistics_account_num) : 0
            const bR = b.statistics_account_num ? (b.statistics_active_num / b.statistics_account_num) : 0
            return aR - bR
          },
          width: 120,
          align: 'center',
        },
        {
          title: '更新日時',
          dataIndex: 'statistics_date_time',
          scopedSlots: { customRender: 'statistics_date_time' },
          sorter: false,
          width: 130,
          align: 'left',
        },
        {
          title: '所属',
          dataIndex: '',
          scopedSlots: { customRender: 'belong' },
          sorter: false,
          width: 70,
          align: 'center',
        },
        {
          title: '編集',
          dataIndex: '',
          scopedSlots: { customRender: 'update' },
          sorter: false,
          width: 70,
          align: 'center',
        },
      ],
    }
  },
  computed: {
    filteredCompanyData() {
      var restCompanyData = this.companyData
      if (this.selectedCompanies.length) restCompanyData = restCompanyData.filter(c => this.selectedCompanies.includes(c.id))
      if (this.selectedCompanieIds.length) restCompanyData = restCompanyData.filter(c => this.selectedCompanieIds.includes(c.id))
      return restCompanyData
    },
    companyDataIds() {
      return this.companyData.map(c => { return { id: c.id, name: c.id } })
    },
  },
  beforeMount() {
    Vue.prototype.$api.send('get', 'master/statistics')
      .then(response => {
        console.log(response)
        this.companyData = response
        this.loading = false
      })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 統計情報の取得に失敗しました。',
        })
        this.loading = false
      })
  },
  filters: {
    formatDateTime: function (dateTime) {
      if (dateTime) {
        return moment(dateTime).format('YYYY/MM/DD HH:mm')
      } else {
        return '-'
      }
    },
    formatRatio: function (ratio) {
      if (ratio) {
        return (ratio * 100).toFixed(2) + '%'
      } else {
        return '-'
      }
    },
  },
  methods: {
    showModalBelong(companyId) {
      this.belongTargetCompany = this.companyData.find(c => c.id === companyId)
      this.title = '所属を ' + this.belongTargetCompany.name + ' に変更しますか？'
      this.visibleBelong = true
    },
    handleOkBelong() {
      const _this = this
      this.confirmLoading = true
      this.dataBelong['company_id'] = this.belongTargetCompany.id
      const result = Vue.prototype.$api.send('put', 'user/change_belong_company', this.dataBelong)
      result.then(response => {
        Vue.prototype.$auth.userInfo()
        _this.$notification['success']({
          message: '所属を ' + this.belongTargetCompany.name + ' に変更しました。',
        })
        // _this.$message.destroy()
        // _this.$message.info({
        //   content: this.belongTargetCompany.id + ': 所属会社を' + this.belongTargetCompany.name + 'にしています。',
        //   duration: 0,
        // })
        this.confirmLoading = false
        this.visibleBelong = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 所属の変更に失敗しました。',
          })
          this.confirmLoading = false
          this.visibleBelong = false
        })
    },
    handleCancelBelong() {
      this.visibleBelong = false
    },
    changeSelectedCompanyName(vals) { this.selectedCompanies = vals },
    changeSelectedCompanyId(vals) { this.selectedCompanieIds = vals },
  },
}
</script>
