var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "mb-3" }, [
      _c(
        "h1",
        {
          class: _vm.$style.pageTitle,
          staticStyle: { display: "inline-block" }
        },
        [_vm._v("統計情報")]
      )
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            {
              staticClass: "card-header",
              staticStyle: { "padding-left": "10px !important" }
            },
            [
              _c("div", { class: _vm.$style.filterHeader }, [
                _vm._v("フィルター")
              ]),
              _c(
                "a-form",
                { attrs: { layout: "inline" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "社名" } },
                    [
                      _c("form-multiple-select", {
                        staticStyle: { "min-width": "200px" },
                        attrs: {
                          placeholder: "社名",
                          candidates: _vm.companyData
                        },
                        on: {
                          changeSelectedItems: _vm.changeSelectedCompanyName
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "ID" } },
                    [
                      _c("form-multiple-select", {
                        staticStyle: { "min-width": "200px" },
                        attrs: {
                          placeholder: "ID",
                          candidates: _vm.companyDataIds
                        },
                        on: { changeSelectedItems: _vm.changeSelectedCompanyId }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("hr", { staticStyle: { margin: "20px 0 0 0" } })
            ],
            1
          ),
          _c("div", { staticClass: "card-body" }, [
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "text-center" },
                  [_c("a-spin", { attrs: { tip: "Loading..." } })],
                  1
                )
              : _vm._e(),
            !_vm.loading
              ? _c(
                  "div",
                  [
                    _c("a-table", {
                      attrs: {
                        columns: _vm.columns,
                        "data-source": _vm.filteredCompanyData,
                        pagination: { pageSize: 20 },
                        rowKey: "id",
                        rowClassName: function(r, i) {
                          return i % 2 === 0
                            ? _vm.$style.stripe0
                            : _vm.$style.stripe1
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "name",
                            fn: function(name, record) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(record.id) + ": " + _vm._s(name))
                              ])
                            }
                          },
                          {
                            key: "ratio_active",
                            fn: function(id, record) {
                              return _c("span", {}, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatRatio")(
                                      record.statistics_active_num /
                                        record.statistics_account_num
                                    )
                                  )
                                )
                              ])
                            }
                          },
                          {
                            key: "statistics_date_time",
                            fn: function(statistics_date_time) {
                              return _c("span", {}, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDateTime")(
                                      statistics_date_time
                                    )
                                  )
                                )
                              ])
                            }
                          },
                          {
                            key: "belong",
                            fn: function(id, record) {
                              return _c(
                                "span",
                                {},
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        shape: "round"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.showModalBelong(record.id)
                                        }
                                      }
                                    },
                                    [_vm._v("所属")]
                                  )
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "update",
                            fn: function(id, record) {
                              return _c(
                                "span",
                                {},
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "companyUpdate",
                                          params: {
                                            id: record.id,
                                            company: record
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: {
                                            padding: "0 10px",
                                            "margin-right": "4px"
                                          }
                                        },
                                        [_vm._v("編集")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        950523590
                      )
                    }),
                    _c("a-modal", {
                      attrs: {
                        title: _vm.title,
                        visible: _vm.visibleBelong,
                        "confirm-loading": _vm.confirmLoading,
                        okText: "所属する",
                        okType: "primary",
                        cancelText: "キャンセル"
                      },
                      on: {
                        ok: _vm.handleOkBelong,
                        cancel: _vm.handleCancelBelong
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }