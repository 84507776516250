<template>
  <div>
    <a-select
      v-if="!disabled"
      mode="multiple"
      :placeholder="placeholder"
      :value="selectedItems"
      :style="styles ? styles : 'min-width: 200px;'"
      :defaultValue="defaultValue"
      :disabled="disabled"
      @change="handleChange"
      :filter-option="filterOption"
    >
      <a-select-option
        v-for="item in filteredOptions"
        :key="item.id"
        :value="item.id"
      >
        {{ item.name }}
      </a-select-option>
    </a-select>
    <a-tag v-if="disabled">{{ selectName }}</a-tag>
  </div>
</template>

<script>

export default {
  name: 'FormMultipleSelect',
  data() {
    return {
      selectedItems: this.defaultValue ? this.defaultValue : [],
      selectName: '',
    }
  },
  props: [
    'styles',
    'placeholder',
    'candidates',
    'defaultValue',
    'disabled',
  ],
  computed: {
    filteredOptions() {
      return this.candidates.filter(o => !this.selectedItems.includes(o))
    },
  },
  watch: {
    disabled: function(val) {
      const selectObj = this.candidates.find((o) => o.id === Number(this.defaultValue[0]))
      this.selectName = selectObj.name
    },
  },
  methods: {
    handleChange(selectedItems) {
      this.selectedItems = selectedItems
      this.$emit('changeSelectedItems', selectedItems)
    },
    filterOption(inputValue, option) {
      return option.componentOptions.children[0].text.includes(inputValue)
    },
  },
  mounted() {
    if (this.defaultValue && this.disabled) {
      const selectObj = this.candidates.find((o) => o.id === Number(this.defaultValue[0]))
      this.selectName = selectObj.name
    }
  },
}
</script>
